import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { getDjs, getVenues, getVenueLogos } from "../api";
import readStreamAsBase64 from "../utils/readStreamAsBase64";
import { AddPhotoAlternate } from "@mui/icons-material";
import resizeFile from "../utils/resizeFile";
import UserContext from "../contexts/UserContext";

export default function EventModal(props) {
  const nameInputRef = useRef();
  const dateInputRef = useRef();
  const liveStreamInputRef = useRef();
  const ticketsInputRef = useRef();
  const descriptionInputRef = useRef();

  const [state, setState] = useState({ data: [] });

  const userContext = useContext(UserContext);

  useEffect(() => {
    (async () => {
      const djData = await getDjs();
      setState((s) => ({
        ...s,
        data: djData
          ? djData.map((dj) => ({
              label: dj.name || dj.username,
              value: dj.id,
            }))
          : [],
      }));
      if (userContext.user.role === "admin") {
        const venues = await getVenues();
        setState((s) => {
          const venueItem = venues.find((x) => x.id === s.venueId);
          const venue = venueItem && {
            label: venueItem.name,
            value: venueItem.id,
          };
          return {
            ...s,
            venues,
            venue,
          };
        });
      }
    })();
  }, [userContext.user.role]);

  useEffect(() => {
    setState((s) => ({
      ...s,
      ...props.event,
      eventPhotoFile: props.event?.image?.uri,
    }));
  }, [props.event]);

  const handleDjsChange = (e, values) => {
    setState((s) => ({
      ...s,
      djs: values.map((x) => x.value),
    }));
  };

  const handleVenueChange = (e, value) => {
    setState((s) => {
      const venueItem = s.venues.find((x) => x.id === value.value);
      const venue = venueItem && {
        label: venueItem.name,
        value: venueItem.id,
      };
      return {
        ...s,
        venue,
      };
    });
  };

  useEffect(() => {
    (async () => {
      if (state.venue && !state.eventPhotoFile) {
        const [{ logo }] = await getVenueLogos([{ id: state.venue.value }]);
        setState((s) => ({
          ...s,
          eventPhotoFile: logo.uri,
        }));
      }
    })();
  }, [state.venue, state.eventPhotoFile]);

  const handleChangeEventPhoto = async (e) => {
    const eventPhoto = await resizeFile(e.target.files[0]);
    setState((s) => ({
      ...s,
      eventPhoto,
      eventPhotoFile: URL.createObjectURL(e.target.files[0]),
      eventPhotoHasChanged: true,
    }));
  };

  const handleIsKaraokeChange = (e) => {
    setState((s) => ({
      ...s,
      isKaraoke: e.target.checked,
    }));
  };

  const handleIsSportsChange = (e) => {
    setState((s) => ({
      ...s,
      isSports: e.target.checked,
    }));
  };

  const handleConfirm = async () => {
    if (!nameInputRef.current.value) {
      setState((s) => ({
        ...s,
        errors: {
          name: "Name is required.",
        },
      }));
      return;
    }

    if (!(state.venue?.value || state.venueId)) {
      setState((s) => ({
        ...s,
        errors: {
          venueId: "Venue is required.",
        },
      }));
      return;
    }

    if (!dateInputRef.current.value) {
      setState((s) => ({
        ...s,
        errors: {
          date: "Date is required.",
        },
      }));
      return;
    }

    if (!parseInt(state.maxOrdersPerUser)) {
      setState((s) => ({
        ...s,
        errors: {
          maxOrdersPerUser: "User Max Playlist Requests is required.",
        },
      }));
      return;
    }

    setState((s) => ({
      ...s,
      isLoading: true,
      errors: undefined,
    }));
    const base64EventPhoto = state.eventPhotoHasChanged
      ? await readStreamAsBase64(state.eventPhoto)
      : undefined;
    const body = {
      id: state.id,
      venueId: state.venue?.value || state.venueId,
      name: nameInputRef.current.value,
      date: dateInputRef.current.value,
      maxOrdersPerUser: parseInt(state.maxOrdersPerUser),
      maxSuggestionsPerUser: parseInt(state.maxSuggestionsPerUser),
      isKaraoke: state.isKaraoke,
      isSports: state.isSports,
      liveStream: liveStreamInputRef.current.value,
      tickets: ticketsInputRef.current.value,
      description: descriptionInputRef.current.value,
      djs: state.djs || [],
      base64: base64EventPhoto,
    };
    await props.handleConfirm(body);
    props.handleClose();
  };

  const handleChange = (propName) => (event) => {
    setState((s) => ({
      ...s,
      [propName]: event.target.value,
    }));
  };

  return (
    <Dialog
      open={true}
      onClose={props.handleClose}
      PaperProps={{
        style: {
          backgroundColor: "#d8c8f4",
          color: "#4b445b",
        },
      }}
    >
      <DialogTitle>{state.id ? "Edit Event" : "Create Event"}</DialogTitle>
      <DialogContent>
        {userContext.user.role === "admin" && state.venues?.length ? (
          <Autocomplete
            disablePortal
            id="venueId-dropdown"
            options={state.venues.map((x) => ({
              label: x.name,
              value: x.id,
            }))}
            value={state.venue}
            disableClearable
            onChange={handleVenueChange}
            renderInput={(params) => (
              <TextField
                margin="dense"
                fullWidth
                variant="outlined"
                label="Venue"
                id="venueId"
                type="text"
                required
                error={!!state.errors?.venueId}
                helperText={state.errors?.venueId}
                {...params}
              />
            )}
          />
        ) : null}
        <TextField
          autoComplete="off"
          margin="dense"
          variant="outlined"
          fullWidth
          label="Event Photo"
          multiline
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: "div",
          }}
          inputProps={{
            children: (
              <>
                {state.eventPhotoFile && (
                  <img
                    alt={state.name}
                    src={state.eventPhotoFile}
                    height="200"
                    style={{ borderRadius: "8px" }}
                  />
                )}
                <div>
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{ marginTop: "4px" }}
                  >
                    <AddPhotoAlternate
                      sx={{ fontSize: "20px", marginRight: "4px" }}
                    />
                    {state.eventPhotoFile ? "Change" : "Add"}
                    <input
                      type="file"
                      hidden
                      onChange={handleChangeEventPhoto}
                    />
                  </Button>
                </div>
              </>
            ),
          }}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="name"
          label="Event Name"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={nameInputRef}
          defaultValue={state.name}
          required
          error={!!state.errors?.name}
          helperText={state.errors?.name}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="date"
          label="Date"
          type="date"
          fullWidth
          variant="outlined"
          inputRef={dateInputRef}
          defaultValue={state.date}
          required
          error={!!state.errors?.date}
          helperText={state.errors?.date}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="maxOrdersPerUser"
          label="User Max Playlist Requests"
          type="number"
          fullWidth
          variant="outlined"
          value={state.maxOrdersPerUser}
          onChange={handleChange("maxOrdersPerUser")}
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={state.maxOrdersPerUser}
          required
          error={!!state.errors?.maxOrdersPerUser}
          helperText={state.errors?.maxOrdersPerUser}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="maxSuggestionsPerUser"
          label="User Max Custom Requests"
          type="number"
          fullWidth
          variant="outlined"
          value={state.maxSuggestionsPerUser}
          onChange={handleChange("maxSuggestionsPerUser")}
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={state.maxSuggestionsPerUser}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.isKaraoke}
              onChange={handleIsKaraokeChange}
            />
          }
          label="Karaoke Mode"
          key="isKaraoke"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.isSports}
              onChange={handleIsSportsChange}
            />
          }
          label="Sports Mode"
          key="isSports"
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="liveStream"
          label="Live Stream"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={liveStreamInputRef}
          defaultValue={state.liveStream}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="tickets"
          label="Tickets"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={ticketsInputRef}
          defaultValue={state.tickets}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="description"
          label="Description"
          type="text"
          fullWidth
          variant="outlined"
          inputRef={descriptionInputRef}
          defaultValue={state.description}
          multiline
          rows={6}
        />
        {state.data?.length ? (
          <Autocomplete
            disablePortal
            id="djs-combo-box"
            options={state.data.filter((x) => !state.djs?.includes(x.value))}
            value={state.data.filter((x) => state.djs?.includes(x.value))}
            multiple
            onChange={handleDjsChange}
            renderInput={(params) => (
              <TextField
                margin="dense"
                fullWidth
                variant="outlined"
                label="DJs"
                id="djs"
                type="text"
                {...params}
              />
            )}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} variant="contained">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={state.isLoading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
